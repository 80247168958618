import React, { useContext, createContext, useState } from "react";
import { useMutation } from "@apollo/client";
import LoginMutation from "../Queries/LoginMutation";
import { Login_login } from "../Queries/types/Login";
import { LoginInput } from "../types/graphql-global-types";
import { useCookies } from "react-cookie";

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
interface IAuthContext {
  user: null | string;
  isAuthenticated: () => Boolean;
  signin: Function;
  signout: Function;
}
const authContext = createContext<IAuthContext>({
  user: null,
  isAuthenticated: () => false,
  signin: () => {},
  signout: () => {},
});

export default function ProvideAuth({ children }: { children: JSX.Element }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth(): IAuthContext {
  const [user, setUser] = useState<null | string>(null);
  const [cookie, setCookie, removeCookie] = useCookies(["token"]);
  const isAuthenticated = () => {
    return cookie.token;
  };

  const [login] = useMutation<{ login: Login_login }, { input: LoginInput }>(
    LoginMutation,
    {
      onCompleted: (data) => {
        setCookie("token", data.login.token, { path: "/" }); // TODO more secure cookie options
        setUser("TODO"); // TODO CHANGEME
      },
      onError: (error) => {
        //   console.table(error.extraInfo);
        //   console.table(error.message);
        error.graphQLErrors.map((e) => console.table(e)); // TODO graceful degradation
      },
    }
  );

  const signin = async (username: string, password: string) => {
    await login({
      variables: { input: { name: username, password: password } },
    });
  };

  const signout = (cb: Function) => {
    removeCookie("token"); // TODO more secure cookie options
    setUser(null); // TODO CHANGEME
  };

  return {
    user,
    isAuthenticated,
    signin,
    signout,
  };
}
