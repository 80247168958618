import React, { useState } from "react";

import NotifyLaunchMutation from "../Queries/NotifyLaunchMutation";
import { NotifyLaunch_notifyLaunch } from "../Queries/types/NotifyLaunch";
import { NotifyLanuchInput } from "../types/graphql-global-types";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

export default function Home() {
  const [email, setEmail] = useState("");
  const [notifyLaunch] = useMutation<
    { response: NotifyLaunch_notifyLaunch },
    { input: NotifyLanuchInput }
  >(NotifyLaunchMutation, {
    variables: { input: { email: email } },
  });
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await notifyLaunch();
    history.push("/notify-launch-success");
  };
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="relative pt-7 pb-16 sm:pb-24 lg:pb-32">
        <main className="mt-17 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-13 lg:gap-8">
            <div className="sm:text-center md:max-w-3xl md:mx-auto lg:col-span-6 lg:text-left">
              <img
                className="mx-auto h-12 w-auto"
                src="https://i.imgur.com/ULgr6bH.png"
                alt="logo"
              />
              <div className="mt-9 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                <form onSubmit={handleSubmit} className="mt-4 sm:flex">
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full py-4 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 border-gray-300"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="mt-4 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                  >
                    Request Access
                  </button>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
