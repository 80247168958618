import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import settings from "../Settings";

const link = createHttpLink({
  uri: settings.apiUri,
  credentials: "same-origin",
});

export default new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
