import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import client from "./Client";
import Home from "./Page/Home";
import SiginIn from "./Page/SIgnIn";
import SiginUp from "./Page/Signup";
import ProvideAuth, { useAuth } from "./Provider/Auth";
import NotifyLaunchSuccess from "./Page/NotifyLaunchSuccess";
import Layout from "./Page/Layout";
import Main from "./Page/Main";
import { CookiesProvider } from "react-cookie";

export default function AuthExample() {
  return (
    <ApolloProvider client={client}>
      <CookiesProvider>
        <ProvideAuth>
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/signin">
                <SiginIn />
              </Route>
              <Route exact path="/login">
                <SiginIn />
              </Route>
              <Route exact path="/signup">
                <SiginUp />
              </Route>
              <Route exact path="/notify-launch-success">
                <NotifyLaunchSuccess />
              </Route>
              {
                // TODO probably make home conditionally render this on login
                // state? not doing that now because lets make features!
              }
              <PrivateRoute path="/main">
                <Layout>
                  <Main />
                </Layout>
              </PrivateRoute>
            </Switch>
          </Router>
        </ProvideAuth>
      </CookiesProvider>
    </ApolloProvider>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

interface PrivateRouteProps extends RouteProps {}
function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
